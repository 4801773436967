<template>
  <div class="container">
    <van-nav-bar
        left-text="路线指引"
        left-arrow fixed placeholder
        z-index="99"
        @click-left="$router.push({name: 'Guide'})"
    />
    <div class="content">
      <h2>成都办公区</h2>
      <div class="info">
        <div class="info_item one">
          <div class="title">
            <img src="../../../static/img/1.png" alt="">
            <p>面试地址</p>
          </div>
          <p class="txt">本次面试地址四川省成都市武侯区武科西五路盈创创意中心2栋1206</p>
        </div>
        <div class="info_item two">
          <div class="title">
            <img src="../../../static/img/2.png" alt="">
            <p>乘车路线</p>
          </div>
          <div class="item first" style="border: 0">
            <p class="text">地铁路线：目的地武青西四路武青南路口站</p>
            <p class="text" style="text-indent: 0">途径地铁：地铁9号线机投桥站（F口）</p>
          </div>
          <div class="tip">
            <div class="tip_title">
              <img src="../../../static/img/3.png" alt="">
              <p><span>温馨小提示：</span>微信搜索小程序—天府通，可以实时把控公交到站时间，避免寒风中等待哦~~</p>
            </div>
            <div class="ByBus">
              <img src="../../../static/img/4.png" alt="">
            </div>
            <div class="ByBus">
              <img src="../../../static/img/cd-1.jpg" alt="" style="width: 100%">
            </div>
            <!--            <div class="car">-->
            <!--              <p>新明辉班车路线图</p>-->
            <!--              <img src="../../../static/img/car.jpg" alt="">-->
            <!--            </div>-->
          </div>
        </div>
        <!--        <div class="info_item three">-->
        <!--          <div class="title">-->
        <!--            <img src="../../../static/img/5.png" alt="">-->
        <!--            <p>公司环境</p>-->
        <!--          </div>-->
        <!--          <p class="txt">到达顺和母幼采购基地楼下后，根据你当前的位置，选择相应的视频观看，帮你导航到达目的地！</p>-->
        <!--          <div class="video">-->
        <!--            <p class="video_title">由顺和母幼西门到达目的地</p>-->
        <!--            <video controls src="../../../static/video/shunhexi.mp4" poster="../../../static/img/17.png"></video>-->
        <!--          </div>-->
        <!--          <div class="video">-->
        <!--            <p class="video_title">由顺和母幼东门到达目的地</p>-->
        <!--            <video controls src="../../../static/video/shunhedong.mp4" poster="../../../static/img/18.png"></video>-->
        <!--          </div>-->
        <!--          <div class="video">-->
        <!--            <p class="video_title">由顺和母幼南门到达目的地</p>-->
        <!--            <video controls src="../../../static/video/shunhenan.mp4" poster="../../../static/img/19.png"></video>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "shjunhe"
}
</script>

<style scoped lang="scss">
p{
  margin: 0;
}
.content{
  width: 335px;
  margin: 0 auto;
  h2{
    font-family: PingFang-SC-Medium;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #666666;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0;
  }
  .workPlace{
    font-family: PingFang-SC-Medium;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #999999;
    text-align: center;
    margin-top: 7px;
  }
  .info{
    padding-top: 15px;
    padding-bottom: 30px;
    .info_item{
      .title{
        width: 130px;
        height: 37px;
        background-color: #02dbca;
        box-shadow: 0px 0px 7px 0px
        rgba(0, 0, 0, 0.11);
        border-radius: 19px;
        display: flex;
        align-items: center;
        margin-top: 5px;
        img{
          width: 30px;
          height: 30px;
          flex-shrink: 0;
          margin-left: 5px;
        }
        p{
          font-family: PingFang-SC-Medium;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
          margin-left: 7px;
        }
      }
      .txt{
        font-family: PingFang-SC-Medium;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #666666;
        line-height: 22px;
        padding: 15px 0;
      }
    }
    .one{

    }
    .two{
      .title{
        background-color: #3399ff;
      }
      .item{
        font-family: PingFang-SC-Medium;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        line-height: 25px;
        color: #666666;
        padding: 18px 0 14px;
        border-bottom: 1px dashed #d4d4d4;
        .text{
          display: flex;
          span{
            display: block;
            margin-right: 2px;
            margin-top: -1px;
          }
        }
        &:last-child{
          border:none;
        }
        .text:last-child{
          text-indent: 1em;
        }
      }
      .tip{
        width: 100%;
        background-color: #ffffff;
        box-shadow: 0px 0px 7px 0px
        rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        padding: 20px 18px;
        border-radius: 7px;
        margin-top: 12px;
        margin-bottom: 25px;
        .tip_title{
          display: flex;
          img{
            width: 22px;
            height: 22px;
            margin-right: 10px;
            margin-top: 1px;
          }
          p{
            font-family: PingFang-SC-Medium;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            line-height: 22px;
            color: #ff3333;
            span{
              font-family: PingFang-SC-Bold;
            }
          }
        }
        .ByBus{
          margin-top: 25px;
          img{
            width: 217px;
            margin: 0 auto;
            display: block;
          }
        }
        .car{
          padding-top: 17px;
          p{
            color: #ff3333;
            font-size: 14px;
            padding-bottom: 5px;
          }
          img{
            display: block;
            width: 100%;
          }
        }
      }
      .last{
        border: none;
        padding-top: 8px;
      }
    }
    .three{
      .title{
        width: 204px;
        background-color: #66cc99;
      }
      .txt{
        margin-top: 3px;
        margin-bottom: 10px;
      }
      .video{
        background-color: #ffffff;
        box-shadow: 0px 0px 7px 0px
        rgba(0, 0, 0, 0.1);
        width: 100%;
        border-radius: 7px;
        box-sizing: border-box;
        padding: 20px 15px;
        margin-bottom: 20px;
        .video_title{
          font-family: PingFang-SC-Medium;
          font-size: 17px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #666666;
          text-align: center;
        }
        video{
          width: 100%;
          margin-top: 10px;
          object-fit: fill;
        }
      }
    }
  }
}
::v-deep .van-nav-bar__arrow{
  font-size: 19px;
  color: #666666;
}
::v-deep .van-nav-bar__text{
  font-family: PingFang-SC-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
}
</style>
